import { useState, useEffect } from 'react';
import { install } from "resize-observer";

import { motion, AnimatePresence } from 'framer-motion';

import { YMInitializer } from 'react-yandex-metrika';

import PageLoader from '../components/PageLoader';

import '../styles/globals.css'


function Bridge({ Component, pageProps, router }) {
    const [bgColor, setBgColor] = useState(router.asPath === '/estate/' ? '#161616' : '#EAEAEA');
    const preloaderTheme = router.asPath === '/' || router.asPath === '/estate/' ? 'dark' : 'light';

    const variants = {
        hidden: {
            opacity: 0,
            translateY: 'none'
        },
        enter: {
            opacity: 1,
            translateY: 'none'
        },
        exit: {
            opacity: 0,
            translateY: '100%'
        }
    }

    const onAnimationComplete = () => {
        document.documentElement.classList.remove('locked')
        document.documentElement.classList.remove('cursor-wait')
    };

    const changeScrollbar = theme => {
        if (theme === 'dark') {
            document.documentElement.classList.remove('scrollbar-light')
            document.documentElement.classList.add('scrollbar-dark')
        } else if (theme === 'light') {
            document.documentElement.classList.remove('scrollbar-dark')
            document.documentElement.classList.add('scrollbar-light')
        }
    }

    const changeMetaThemeColor = color => {
        document.head.querySelector('[name="theme-color"]').setAttribute('content', color)
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!window.ResizeObserver) install();
        }

        // thanks to
        // https://stackoverflow.com/questions/65884505/how-to-listen-to-page-changes-in-nextjs

        const getThemeByUrl = (url) => {
            switch (url) {
                case '/design/':
                    return ['light', '#eaeaea']
                case '/estate/':
                    return ['dark', '#161616']
                case '/':
                    return ['dark', '#161616']
                default:
                    return ['light', '#eaeaea']
            }
        }

        const handleRouteChange = (url) => {
            if (!url.includes('#')) {
                document.documentElement.classList.add('locked')
                document.documentElement.classList.add('cursor-wait')

                let [themeName, color] = getThemeByUrl(url)

                changeScrollbar(themeName);
                changeMetaThemeColor(color);
                return setBgColor(color)
            }
        }

        router.events.on('routeChangeStart', handleRouteChange)

        const [themeName, color] = getThemeByUrl(router.asPath)
        changeScrollbar(themeName);
        changeMetaThemeColor(color);

        return () => {
            // If the component is unmounted, unsubscribe
            // from the event with the `off` method:
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [])

    return (
        <>
            <div style={{
                position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -5,
                background: bgColor
            }} />

            <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                    key={router.asPath}
                    variants={variants}
                    initial="hidden"
                    animate="enter"
                    exit="exit"
                    onAnimationComplete={onAnimationComplete}
                    transition={{ type: 'linear', duration: 1 }}
                >
                    <Component {...pageProps} key={router.asPath} />
                </motion.div>
            </AnimatePresence>

            <PageLoader
                shown={true}
                theme={preloaderTheme}
            />

            {process.env.NODE_ENV === 'production' && (
                <YMInitializer accounts={[89459802]} options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                }} />
            )}
        </>
    );
}


export default Bridge;
